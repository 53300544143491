@import "https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
@import "https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
a, abbr, address, article, aside, audio, b, blockquote, body, canvas, caption, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre, q, ruby, section, samp, small, span, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, ul, var, video {
  vertical-align: baseline;
  font: inherit;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, menu, nav, section {
  display: block;
}

body {
  font-family: Open Sans, sans-serif;
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
}

body {
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  margin: 0;
  display: grid;
}

input[type="text"], input[type="email"], textarea {
  appearance: none;
  color: #5a5a5a;
  background: #45cec333;
  border: none;
  border-bottom: .2em solid #45cec3;
  border-radius: .2em .2em 0 0;
  outline: none;
  padding: .4em;
  font-family: Prompt;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.container {
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  padding: 0 48px;
}

.header {
  background-color: #45cec3;
  width: 100%;
  padding: 25px 0;
}

.header__content {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.header__logo {
  width: 100px;
}

.header__logo img {
  width: 100%;
}

.header__nav-list {
  align-items: center;
  gap: 60px;
  display: flex;
}

.header__nav-item a {
  color: #1a615b;
  font-family: Prompt;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}

.header__nav-item a:hover {
  color: #fff;
}

.header__menu-btn {
  cursor: pointer;
  color: #fff;
  background: none;
  border: none;
  font-size: 35px;
  display: none;
}

.sidemenu {
  z-index: 999;
  background-color: #45cec3;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  transition: transform .5s ease-in-out;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.sidemenu.active {
  transform: translateX(0);
}

.sidemenu__close-btn {
  color: #fff;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 50px;
  position: absolute;
  top: 20px;
  right: 20px;
}

.sidemenu__content, .sidemenu__list {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  display: flex;
}

.sidemenu__item a {
  color: #1a615b;
  font-family: Prompt;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}

.sidemenu__item a:hover {
  color: #fff;
}

.intro {
  background-image: linear-gradient(#0004, #0000003a), url("saf5.7e57c474.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.intro__content {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 200px 0;
  display: flex;
}

.intro__title {
  color: #fff;
  max-width: 1070px;
  margin-bottom: 50px;
  font-family: Prompt;
  font-size: 72px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.intro__btn {
  color: #fff;
  cursor: pointer;
  background-color: #45cec3;
  border: none;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  padding: 16px 24px;
  font-family: Prompt;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
}

.intro__btn:hover {
  background-color: #1a615b;
}

.mainabout {
  width: 100%;
  padding: 150px 0 75px;
}

.mainabout__content {
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  display: flex;
}

.mainabout__img {
  width: 100%;
  max-width: 535px;
}

.mainabout__img img {
  width: 100%;
}

.mainabout__subtitle {
  letter-spacing: 3.6px;
  text-transform: uppercase;
  color: #45cec3;
  margin-bottom: 10px;
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.mainabout__title {
  text-transform: uppercase;
  color: #000;
  max-width: 700px;
  margin-bottom: 45px;
  font-family: Prompt;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.mainabout__text {
  max-width: 100%;
  margin-bottom: 45px;
  font-family: Prompt;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
}

.mainservices {
  width: 100%;
  padding: 75px 0;
}

.mainservices__content {
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.mainservices .mainabout__title {
  text-align: center;
  width: 100%;
}

.mainservices ul {
  gap: 125px;
  margin-bottom: 50px;
  display: flex;
}

.mainservices__item {
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 371px;
  display: flex;
}

.mainservices__item-img {
  margin-bottom: 40px;
}

.mainservices__item-title {
  margin-bottom: 20px;
  font-family: Prompt;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.mainservices__item-text {
  color: #5a5a5a;
  margin-bottom: 10px;
  font-family: Prompt;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
}

.mainvideo {
  width: 100%;
  padding: 75px 0;
}

.mainvideo__content {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.mainvideo__content .mainabout__title {
  text-align: center;
  width: 100%;
  max-width: 1000px;
}

video {
  width: 100%;
  height: auto;
}

.footer {
  background-color: #45cec3;
  border-top: 1px solid #e5e5e5;
  padding: 50px 0;
}

.footer__content {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.footer__logo {
  max-width: 200px;
}

.footer__logo img {
  width: 100%;
}

.footer__links ul {
  gap: 100px;
  display: flex;
}

.footer__links ul li {
  flex-direction: column;
  display: flex;
}

.footer__links ul a {
  color: #1a615b;
  font-family: Prompt;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}

.footer__links ul a:hover {
  color: #fff;
}

.footer__links ul p {
  font-family: Prompt;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
}

.footer__links ul h2 {
  margin-bottom: 35px;
  font-family: Prompt;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.about {
  width: 100%;
  padding: 50px 0 75px;
}

.about iframe {
  height: 500px;
  width: 100% !important;
}

.about .mainservices__item-text {
  font-size: 24px;
}

.about__form {
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  margin-bottom: 50px;
  display: flex;
}

.about__form div p {
  margin-bottom: 10px;
}

.about__form form {
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
}

.about__form form .intro__btn {
  width: 100%;
}

.about .mainabout__title {
  text-align: center;
  max-width: inherit;
  width: 100%;
}

.about__companies {
  padding: 75px 0 50px;
}

.about__images {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 50px;
  display: flex;
}

.about__images img {
  width: 200px;
}

.about__intro {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
  display: flex;
}

.about__intro img {
  width: 100%;
  max-width: 550px;
}

.media {
  width: 100%;
  padding: 75px 0 50px;
}

.media .mainabout__title {
  text-align: center;
  max-width: inherit;
  width: 100%;
}

.media__content {
  grid-template-columns: repeat(auto-fill, minmax(300px, 300px));
  justify-content: center;
  gap: 20px;
  display: grid;
}

.media__content img {
  width: 100%;
  max-width: 300px;
  height: 168px;
}

@media (width <= 1000px) {
  .mainabout {
    padding: 100px 0 50px;
  }

  .mainabout__content {
    flex-direction: column;
    gap: 50px;
  }

  .mainabout__subtitle {
    font-size: 16px;
  }

  .mainabout__title {
    font-size: 42px;
  }

  .mainabout__text {
    font-size: 14px;
  }

  .mainservices {
    padding: 50px 0;
  }

  .mainservices ul {
    flex-direction: column;
    gap: 50px;
  }
}

@media (width <= 850px) {
  .container {
    padding: 0 35px;
  }

  .header__nav {
    display: none;
  }

  .header__menu-btn {
    display: block;
  }

  .header__space {
    display: none;
  }

  .intro__content {
    padding: 100px 0;
  }

  .intro__title {
    font-size: 50px;
  }

  .footer__content, .footer__links ul {
    flex-direction: column;
    align-items: start;
    gap: 50px;
  }
}

@media (width <= 450px) {
  .container {
    padding: 0 20px;
  }

  .intro__title {
    margin-bottom: 30px;
    font-size: 25px;
  }

  .intro__btn {
    padding: 10px 16px;
    font-size: 14px;
  }

  .mainabout__subtitle {
    font-size: 14px;
  }

  .mainabout__title {
    font-size: 30px;
  }

  .mainabout__text {
    font-size: 14px;
  }
}

/*# sourceMappingURL=index.1870a21a.css.map */
