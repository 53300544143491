@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

a,
abbr,
address,
article,
aside,
audio,
b,
blockquote,
body,
canvas,
caption,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
section,
samp,
small,
span,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
ul,
var,
video {
  margin: 0;
  border: 0;
  padding: 0;
  vertical-align: baseline;
  font: inherit;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  font-family: "Open Sans", sans-serif;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;

  &::before,
  &::after {
    content: none;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  margin: 0;

  display: grid;
  grid-template-rows: auto 1fr auto;
}

input[type="text"] {
  appearance: none;
  border: none;
  outline: none;
  border-bottom: 0.2em solid $main--color;
  background: rgba($main--color, 0.2);
  border-radius: 0.2em 0.2em 0 0;
  padding: 0.4em;
  color: rgb(90, 90, 90);
  font-family: Prompt;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

input[type="email"] {
  appearance: none;
  border: none;
  outline: none;
  border-bottom: 0.2em solid $main--color;
  background: rgba($main--color, 0.2);
  border-radius: 0.2em 0.2em 0 0;
  padding: 0.4em;
  color: rgb(90, 90, 90);
  font-family: Prompt;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

textarea {
  appearance: none;
  border: none;
  outline: none;
  border-bottom: 0.2em solid $main--color;
  background: rgba($main--color, 0.2);
  border-radius: 0.2em 0.2em 0 0;
  padding: 0.4em;
  color: rgb(90, 90, 90);
  font-family: Prompt;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.container {
  max-width: 1500px;
  padding: 0 48px;
  width: 100%;
  margin: 0 auto;
}

.header {
  background-color: $main--color;
  padding: 25px 0;
  width: 100%;

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo {
    width: 100px;

    & img {
      width: 100%;
    }
  }

  &__nav {
    &-list {
      display: flex;
      align-items: center;
      gap: 60px;
    }

    &-item {
      & a {
        font-family: Prompt;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration: none;
        color: $main--font;

        &:hover {
          color: #fff;
        }
      }
    }
  }

  &__menu-btn {
    display: none;
    cursor: pointer;
    font-size: 35px;
    color: #fff;
    background: none;
    border: none;
  }
}

.sidemenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: $main--color;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transform: translateX(-100%);
  transition: transform 0.5s ease-in-out;

  &.active {
    transform: translateX(0);
  }

  &__close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 50px;
    color: #fff;
    background: none;
    border: none;
    cursor: pointer;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 50px;
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 50px;
  }

  &__item {
    & a {
      font-family: Prompt;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration: none;
      color: $main--font;

      &:hover {
        color: #fff;
      }
    }
  }
}

.intro {
  background-image: linear-gradient(rgba(0, 0, 0, 0.267), rgba(0, 0, 0, 0.226)),
    url("assets/saf5.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &__content {
    padding: 200px 0 200px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__title {
    font-family: Prompt;
    font-size: 72px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    color: #fff;
    max-width: 1070px;
    margin-bottom: 50px;
  }

  &__btn {
    font-family: Prompt;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #fff;
    padding: 16px 24px;
    display: flex;
    justify-content: center;
    font-weight: 600;
    align-items: center;
    background-color: $main--color;
    cursor: pointer;
    border: none;
    border-radius: 50px;
    width: fit-content;

    &:hover {
      background-color: $main--font;
    }
  }
}

.mainabout {
  width: 100%;
  padding: 150px 0 75px;

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
  }

  &__img {
    width: 100%;
    max-width: 535px;

    & img {
      width: 100%;
    }
  }

  &__subtitle {
    font-family: Work Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 3.6px;
    text-transform: uppercase;
    color: $main--color;
    margin-bottom: 10px;
  }

  &__title {
    font-family: Prompt;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    text-transform: uppercase;
    color: #000;
    margin-bottom: 45px;
    max-width: 700px;
  }

  &__text {
    font-family: Prompt;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%;
    margin-bottom: 45px;
    max-width: 650px;
  }
}

.mainservices {
  width: 100%;
  padding: 75px 0 75px;

  &__content {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & .mainabout__title {
    text-align: center;
    width: 100%;
  }

  & ul {
    display: flex;
    gap: 125px;
    margin-bottom: 50px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 371px;
    width: 100%;

    &-img {
      margin-bottom: 40px;
    }

    &-title {
      font-family: Prompt;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 20px;
    }

    &-text {
      font-family: Prompt;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 180%;
      color: #5a5a5a;
      margin-bottom: 10px;
    }
  }
}

.mainvideo {
  width: 100%;
  padding: 75px 0;

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    & .mainabout__title {
      width: 100%;
      max-width: 1000px;
      text-align: center;
    }
  }
}

video {
  width: 100%;
  height: auto;
}

.footer {
  padding: 50px 0;
  border-top: 1px solid #e5e5e5;
  background-color: $main--color;

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo {
    max-width: 200px;

    & img {
      width: 100%;
    }
  }

  &__links {
    & ul {
      display: flex;
      gap: 100px;

      & li {
        display: flex;
        flex-direction: column;
      }

      & a {
        font-family: Prompt;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration: none;
        color: $main--font;

        &:hover {
          color: #fff;
        }
      }

      & p {
        font-family: Prompt;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 180%;
      }

      & h2 {
        font-family: Prompt;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 35px;
      }
    }
  }
}

.about {
  width: 100%;
  padding: 50px 0 75px;

  & iframe {
    width: 100% !important;
    height: 500px;
  }

  & .mainservices__item-text {
    font-size: 24px;
  }

  &__form {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 50px;
    gap: 20px;
    flex-wrap: wrap;

    & div {
      & p {
        margin-bottom: 10px;
      }
    }

    & form {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;
      margin-bottom: 20px;

      & .intro__btn {
        width: 100%;
      }
    }
  }

  & .mainabout__title {
    text-align: center;
    max-width: inherit;
    width: 100%;
  }

  &__companies {
    padding: 75px 0 50px;
  }

  &__images {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 50px;

    & img {
      width: 200px;
    }
  }

  &__intro {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-bottom: 30px;

    & img {
      width: 100%;
      max-width: 550px;
    }
  }
}

.media {
  width: 100%;
  padding: 75px 0 50px;

  & .mainabout__title {
    text-align: center;
    max-width: inherit;
    width: 100%;
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 300px));
    gap: 20px;
    justify-content: center;

    & img {
      width: 100%;
      max-width: 300px;
      height: 168px;
    }
  }
}

@media (max-width: 1000px) {
  .mainabout {
    padding: 100px 0 50px;

    &__content {
      flex-direction: column;
      gap: 50px;
    }

    &__subtitle {
      font-size: 16px;
    }

    &__title {
      font-size: 42px;
    }

    &__text {
      font-size: 14px;
    }
  }

  .mainservices {
    padding: 50px 0 50px;

    & ul {
      flex-direction: column;
      gap: 50px;
    }
  }
}

@media (max-width: 850px) {
  .container {
    padding: 0 35px;
  }

  .header {
    &__nav {
      display: none;
    }

    &__menu-btn {
      display: block;
    }

    &__space {
      display: none;
    }
  }

  .intro {
    &__content {
      padding: 100px 0 100px;
    }

    &__title {
      font-size: 50px;
    }
  }

  .footer {
    &__content {
      flex-direction: column;
      align-items: start;
      gap: 50px;
    }

    &__links {
      & ul {
        flex-direction: column;
        align-items: start;
        gap: 50px;
      }
    }
  }
}

@media (max-width: 450px) {
  .container {
    padding: 0 20px;
  }

  .intro {
    &__title {
      font-size: 25px;
      margin-bottom: 30px;
    }

    &__btn {
      font-size: 14px;
      padding: 10px 16px;
    }
  }

  .mainabout {
    &__subtitle {
      font-size: 14px;
    }

    &__title {
      font-size: 30px;
    }

    &__text {
      font-size: 14px;
    }
  }
}
